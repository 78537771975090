




















































































































































































































































































































































































































































































































































































































































import editMixin from "@/mixin/edit-mixin";
import UtilityString from "@/utilities/strings";
import MODULE from "@/constants/modules";
const utility = new UtilityString();
export default {
  mixins: [editMixin],
  methods: {
    formatPhoneNumber(propertyName) {
      this.currentDataObj.details[propertyName] = utility.formatPhoneNumber(this.currentDataObj.details[propertyName]);
    },
  },
  created() {
    this.MODULE = MODULE;
  },
};
