var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-2 mb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-12 pr-lg-1 mb-lg-0 mb-2"},[_c('b-card',{staticClass:"details"},[_c('div',{staticClass:"header mb-3 d-flex justify-content-between"},[_c('h5',[_vm._v("Supplier Information")]),_c('i',{staticClass:"fa fa-info-circle info-icon text-secondary",attrs:{"id":"basicinfo"}},[_c('b-tooltip',{attrs:{"target":"basicinfo","triggers":"hover","placement":"righttop"}},[_c('div',[_vm._v("Supplier Details")]),_c('p',[_vm._v("Enter Supplier Information")])])],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Supplier Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.BusinessName),expression:"currentDataObj.details.BusinessName"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-supplier-name"},domProps:{"value":(_vm.currentDataObj.details.BusinessName)},on:{"keyup":function($event){return _vm.updateField(
                    'BusinessName', //PropertyName
                    _vm.currentDataObj.details.BusinessName, //Property Value
                    'Business Name', //Display Name,
                    _vm.currentDataObj.details.BusinessName, //Display Value
                    'BusinessName' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "BusinessName", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Phone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Phone),expression:"currentDataObj.details.Phone"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-phone"},domProps:{"value":(_vm.currentDataObj.details.Phone)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Phone", $event.target.value)},function($event){return _vm.formatPhoneNumber('Phone')}],"keyup":function($event){return _vm.updateField(
                    'Phone', //PropertyName
                    _vm.currentDataObj.details.Phone, //Property Value
                    'Phone', //Display Name,
                    _vm.currentDataObj.details.Phone, //Display Value
                    'Phone' //Data object Property Name
                  )}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.PrimaryAddress),expression:"currentDataObj.details.PrimaryAddress"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-address"},domProps:{"value":(_vm.currentDataObj.details.PrimaryAddress)},on:{"keyup":function($event){return _vm.updateField(
                    'PrimaryAddress', //PropertyName
                    _vm.currentDataObj.details.PrimaryAddress, //Property Value
                    'Address', //Display Name,
                    _vm.currentDataObj.details.PrimaryAddress, //Display Value
                    'PrimaryAddress' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "PrimaryAddress", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Fax")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Fax),expression:"currentDataObj.details.Fax"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-fax"},domProps:{"value":(_vm.currentDataObj.details.Fax)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Fax", $event.target.value)},function($event){return _vm.formatPhoneNumber('Fax')}],"keyup":function($event){return _vm.updateField(
                    'Fax', //PropertyName
                    _vm.currentDataObj.details.Fax, //Property Value
                    'Fax', //Display Name,
                    _vm.currentDataObj.details.Fax, //Display Value
                    'Fax' //Data object Property Name
                  )}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Address 2")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Address2),expression:"currentDataObj.details.Address2"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-address-2"},domProps:{"value":(_vm.currentDataObj.details.Address2)},on:{"keyup":function($event){return _vm.updateField(
                    'Address2', //PropertyName
                    _vm.currentDataObj.details.Address2, //Property Value
                    'Address 2', //Display Name,
                    _vm.currentDataObj.details.Address2, //Display Value
                    'Address2' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Address2", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Email),expression:"currentDataObj.details.Email"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-email"},domProps:{"value":(_vm.currentDataObj.details.Email)},on:{"keyup":function($event){return _vm.updateField(
                    'Email', //PropertyName
                    _vm.currentDataObj.details.Email, //Property Value
                    'Email', //Display Name,
                    _vm.currentDataObj.details.Email, //Display Value
                    'Email' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Email", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("City")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.City),expression:"currentDataObj.details.City"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-city"},domProps:{"value":(_vm.currentDataObj.details.City)},on:{"keyup":function($event){return _vm.updateField(
                    'City', //PropertyName
                    _vm.currentDataObj.details.City, //Property Value
                    'City', //Display Name,
                    _vm.currentDataObj.details.City, //Display Value
                    'City' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "City", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Auto CC (auto add emails to cc textbox)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.AutoCCEmail),expression:"currentDataObj.details.AutoCCEmail"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-auto-cc"},domProps:{"value":(_vm.currentDataObj.details.AutoCCEmail)},on:{"keyup":function($event){return _vm.updateField(
                    'AutoCCEmail', //PropertyName
                    _vm.currentDataObj.details.AutoCCEmail, //Property Value
                    'Auto CC', //Display Name,
                    _vm.currentDataObj.details.AutoCCEmail, //Display Value
                    'AutoCCEmail' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "AutoCCEmail", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Province")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ProvinceState),expression:"currentDataObj.details.ProvinceState"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-province"},domProps:{"value":(_vm.currentDataObj.details.ProvinceState)},on:{"keyup":function($event){return _vm.updateField(
                    'ProvinceState', //PropertyName
                    _vm.currentDataObj.details.ProvinceState, //Property Value
                    'Province', //Display Name,
                    _vm.currentDataObj.details.ProvinceState, //Display Value
                    'ProvinceState' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "ProvinceState", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-supplier-details-industry","label":"Industry","actionId":_vm.MODULE.CLIENTS.UX.Industry}}),_c('v-select',{attrs:{"clearable":false,"options":_vm.UXData.uxIndustry,"id":"slidepanel-edit-supplier-industry","disabled":_vm.isDisabled,"value":this.getSelectedDropDownValue('uxIndustry', 'IntIndustryID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Industry', //Display Name
                    'uxIndustry', //Dropdown List Object
                    'IntIndustryID' //Edit Details Property Name
                  )}}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Country")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Country),expression:"currentDataObj.details.Country"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-country"},domProps:{"value":(_vm.currentDataObj.details.Country)},on:{"keyup":function($event){return _vm.updateField(
                    'Country', //PropertyName
                    _vm.currentDataObj.details.Country, //Property Value
                    'Country', //Display Name,
                    _vm.currentDataObj.details.Country, //Display Value
                    'Country' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Country", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-supplier-details-pay-term","label":"Pay Term","actionId":_vm.MODULE.PAYMENT.UX.PaymentTerms}}),_c('v-select',{attrs:{"clearable":false,"options":_vm.UXData.uxPaymentTerms,"id":"slidepanel-edit-supplier-pay-term","disabled":_vm.isDisabled,"value":this.getSelectedDropDownValue('uxPaymentTerms', 'IntPayTermID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Payment Terms', //Display Name
                    'uxPaymentTerms', //Dropdown List Object
                    'IntPayTermID' //Edit Details Property Name
                  )}}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Postal Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.PostalZip),expression:"currentDataObj.details.PostalZip"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-postal-code"},domProps:{"value":(_vm.currentDataObj.details.PostalZip)},on:{"keyup":function($event){return _vm.updateField(
                    'PostalZip', //PropertyName
                    _vm.currentDataObj.details.PostalZip, //Property Value
                    'Postal Code', //Display Name,
                    _vm.currentDataObj.details.PostalZip, //Display Value
                    'PostalZip' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "PostalZip", $event.target.value)}}})])])])])],1),_c('div',{staticClass:"col-lg-6 col-md-12 pl-lg-1"},[_c('b-card',{staticClass:"details"},[_c('div',{staticClass:"header mb-3 d-flex justify-content-between"},[_c('h5',[_vm._v("Primary Contact Details")]),_c('i',{staticClass:"fa fa-info-circle info-icon text-secondary",attrs:{"id":"basicinfo"}},[_c('b-tooltip',{attrs:{"target":"basicinfo","triggers":"hover","placement":"righttop"}},[_c('div',[_vm._v("Primary Contact Details")]),_c('p',[_vm._v("Enter primary contact details")])])],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Postal Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.PostalZip),expression:"currentDataObj.details.PostalZip"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-postal-code"},domProps:{"value":(_vm.currentDataObj.details.PostalZip)},on:{"keyup":function($event){return _vm.updateField(
                    'PostalZip', //PropertyName
                    _vm.currentDataObj.details.PostalZip, //Property Value
                    'Postal Code', //Display Name,
                    _vm.currentDataObj.details.PostalZip, //Display Value
                    'PostalZip' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "PostalZip", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Primary Contact First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.PrimaryContactFirstName),expression:"currentDataObj.details.PrimaryContactFirstName"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-first-name"},domProps:{"value":(_vm.currentDataObj.details.PrimaryContactFirstName)},on:{"keyup":function($event){return _vm.updateField(
                    'PrimaryContactFirstName', //PropertyName
                    _vm.currentDataObj.details.PrimaryContactFirstName, //Property Value
                    'Primary Contact First Name', //Display Name,
                    _vm.currentDataObj.details.PrimaryContactFirstName, //Display Value
                    'PrimaryContactFirstName' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "PrimaryContactFirstName", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Print On Cheque As")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.PrintOnChequeAs),expression:"currentDataObj.details.PrintOnChequeAs"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-print-cheque"},domProps:{"value":(_vm.currentDataObj.details.PrintOnChequeAs)},on:{"keyup":function($event){return _vm.updateField(
                    'PrintOnChequeAs', //PropertyName
                    _vm.currentDataObj.details.PrintOnChequeAs, //Property Value
                    'Print On Cheque As', //Display Name,
                    _vm.currentDataObj.details.PrintOnChequeAs, //Display Value
                    'PrintOnChequeAs' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "PrintOnChequeAs", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Primary Contact Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.PrimaryContactLastName),expression:"currentDataObj.details.PrimaryContactLastName"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-last-name"},domProps:{"value":(_vm.currentDataObj.details.PrimaryContactLastName)},on:{"keyup":function($event){return _vm.updateField(
                    'PrimaryContactLastName', //PropertyName
                    _vm.currentDataObj.details.PrimaryContactLastName, //Property Value
                    'Primary Contact Last Name', //Display Name,
                    _vm.currentDataObj.details.PrimaryContactLastName, //Display Value
                    'PrimaryContactLastName' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "PrimaryContactLastName", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Website")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Website),expression:"currentDataObj.details.Website"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-website"},domProps:{"value":(_vm.currentDataObj.details.Website)},on:{"keyup":function($event){return _vm.updateField(
                    'Website', //PropertyName
                    _vm.currentDataObj.details.Website, //Property Value
                    'Website', //Display Name,
                    _vm.currentDataObj.details.Website, //Display Value
                    'Website' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Website", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Primary Contact Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.PrimaryContactEmail),expression:"currentDataObj.details.PrimaryContactEmail"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-email"},domProps:{"value":(_vm.currentDataObj.details.PrimaryContactEmail)},on:{"keyup":function($event){return _vm.updateField(
                    'PrimaryContactEmail', //PropertyName
                    _vm.currentDataObj.details.PrimaryContactEmail, //Property Value
                    'Primary Contact Email', //Display Name,
                    _vm.currentDataObj.details.PrimaryContactEmail, //Display Value
                    'PrimaryContactEmail' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "PrimaryContactEmail", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Account Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.AccountNumber),expression:"currentDataObj.details.AccountNumber"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-account-number"},domProps:{"value":(_vm.currentDataObj.details.AccountNumber)},on:{"keyup":function($event){return _vm.updateField(
                    'AccountNumber', //PropertyName
                    _vm.currentDataObj.details.AccountNumber, //Property Value
                    'Account Number', //Display Name,
                    _vm.currentDataObj.details.AccountNumber, //Display Value
                    'AccountNumber' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "AccountNumber", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Alternate Phone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.OtherPhone),expression:"currentDataObj.details.OtherPhone"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-alternate-phone"},domProps:{"value":(_vm.currentDataObj.details.OtherPhone)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "OtherPhone", $event.target.value)},function($event){return _vm.formatPhoneNumber('OtherPhone')}],"keyup":function($event){return _vm.updateField(
                    'OtherPhone', //PropertyName
                    _vm.currentDataObj.details.OtherPhone, //Property Value
                    'Alternate Phone', //Display Name,
                    _vm.currentDataObj.details.OtherPhone, //Display Value
                    'OtherPhone' //Data object Property Name
                  )}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Default Days to Delivery Date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.SetDefaultDaystoDeliver),expression:"currentDataObj.details.SetDefaultDaystoDeliver"}],staticClass:"form-control",attrs:{"type":"number","id":"slidepanel-edit-supplier-primary-delivery-date"},domProps:{"value":(_vm.currentDataObj.details.SetDefaultDaystoDeliver)},on:{"keyup":function($event){return _vm.updateField(
                    'SetDefaultDaystoDeliver', //PropertyName
                    _vm.currentDataObj.details.SetDefaultDaystoDeliver, //Property Value
                    'Default Days to Delivery Date', //Display Name,
                    _vm.currentDataObj.details.SetDefaultDaystoDeliver, //Display Value
                    'SetDefaultDaystoDeliver' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "SetDefaultDaystoDeliver", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Alternate Contact")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.AltContact),expression:"currentDataObj.details.AltContact"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-supplier-primary-alternate-contact"},domProps:{"value":(_vm.currentDataObj.details.AltContact)},on:{"keyup":function($event){return _vm.updateField(
                    'AltContact', //PropertyName
                    _vm.currentDataObj.details.AltContact, //Property Value
                    'Alternate Contact', //Display Name,
                    _vm.currentDataObj.details.AltContact, //Display Value
                    'AltContact' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "AltContact", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-supplier-details-delivery-preference","label":"Delivery Preference","actionId":_vm.MODULE.PURCHASEORDER.UX.DeliveryPreference}}),_c('v-select',{attrs:{"clearable":false,"id":"slidepanel-edit-supplier-primary-delivery-preference","options":_vm.UXData.uxDeliveryPreference,"disabled":_vm.isDisabled,"value":this.getSelectedDropDownValue('uxDeliveryPreference', 'IntDeliveryID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Delivery Preference', //Display Name
                    'uxDeliveryPreference', //Dropdown List Object
                    'IntDeliveryID' //Edit Details Property Name
                  )}}})],1),_c('div',{staticClass:"col-md-12 mb-0"},[_c('b-form-checkbox',{staticClass:"mt-3",attrs:{"id":"tagNameRequired","name":"tagNameRequired"},on:{"change":function($event){return _vm.checkboxChanged(
                    'isTagNameRequired', //PropertyName
                    _vm.currentDataObj.details.isTagNameRequired, //Property Value
                    'Tag Name Required', //Display Name
                    false
                  )}},model:{value:(_vm.currentDataObj.details.isTagNameRequired),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "isTagNameRequired", $$v)},expression:"currentDataObj.details.isTagNameRequired"}},[_vm._v(" Tag Name Required ")])],1),_c('div',{staticClass:"col-md-6 my-0"},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"id":"IsInActive","name":"IsInActive"},on:{"change":function($event){return _vm.checkboxChanged(
                    'IsInActive', //PropertyName
                    _vm.currentDataObj.details.IsInActive, //Property Value
                    'Disable Supplier', //Display Name
                    false
                  )}},model:{value:(_vm.currentDataObj.details.IsInActive),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "IsInActive", $$v)},expression:"currentDataObj.details.IsInActive"}},[_c('span',{staticClass:"text-danger"},[_vm._v("Disable Supplier")])])],1),_c('div',{staticClass:"col-md-6 my-0"},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"id":"IsCritical","name":"IsCritical"},on:{"change":function($event){return _vm.checkboxChanged(
                    'IsCritical', //PropertyName
                    _vm.currentDataObj.details.IsCritical, //Property Value
                    'Critical', //Display Name
                    false
                  )}},model:{value:(_vm.currentDataObj.details.IsCritical),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "IsCritical", $$v)},expression:"currentDataObj.details.IsCritical"}},[_vm._v(" Critical ")])],1)])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }